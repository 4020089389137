import React, {useEffect, useRef, useState} from 'react'
import { 
    actionLoginProcess, loginGoogleUser, actionNavigateOnEnter
} from '../../../actions'
import MainButton from '../../../components/MainButton/MainButton'
import '../Login.css'
import CustomTextInput from '../../../components/CustomTextInput/CustomTextInput';
import GoogleLogin from "react-google-login";
import { ReactComponent as BoxCheck } from '../../../assets/images/svgs/boxCheck.svg';
import ReCAPTCHA from "react-google-recaptcha";
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';
import { sonLogo, sonLogoSize } from '../../../data';
import { FormattedMessage } from 'react-intl';

const EmailStep = (props) => {

    const [emailText, setEmailText] = useState('')
    const [reCaptcha, setReCaptcha] = useState(null)
    const [localLogo] = useState(sonLogo)
    const [localLoading, setLocalLoading] = useState(false)
    const navigate = useNavigate();
    const recaptchaRef = useRef();

    const checkRememberAccount = () => {
        const cookies = new Cookies();
        const rem = cookies.get('rememberAccount')
        const acc = cookies.get('userAccessToken')
        if(rem === 'true' && acc) {
            actionNavigateOnEnter(navigate)
        } else {
            cookies.remove('userAccessToken');
        }
    }

    const buttonDisabled = () => {
        return !reCaptcha || !isMail(emailText)
    }

    const eventHandleLoginProcess = (e) => {
        if(e.key === 'Enter' && !buttonDisabled()) {
            handleLoginProcess()
        }
    }

    useEffect(
        () => {
            document.addEventListener("keydown", eventHandleLoginProcess);
            return () => {
                document.removeEventListener("keydown", eventHandleLoginProcess);
            };
        },
        [document, emailText, reCaptcha]
    );

    useEffect(() => {
        props.setRememberAccount(false)
        checkRememberAccount()
    }, [])

    const responseGoogle = (response) => {
        setLocalLoading(true)
        loginGoogleUser(response.tokenId, navigate, props.rememberAccount, props.setLoginProcessData, props.loginProcessData, recaptchaRef, setReCaptcha, setLocalLoading);
    }

    const handleLoginProcess = () => {
        setLocalLoading(true)
        const data = {
            "user_id": emailText,
            "source": "admin",
            "reCaptcha": reCaptcha
        }
        actionLoginProcess(data, props.setLoginProcessData, recaptchaRef, setReCaptcha, setLocalLoading, props.loginProcessData)
    }

    const isMail = (e) => {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(String(e).toLowerCase())) {
          return false;
        } return true
    }

    const reCaptchaCapture = (value) => {
        setReCaptcha(value)
    }

    return (
        <div className='login-container-main'>
            <img style={{ width: sonLogoSize }}  src={localLogo} />
            <p className='login-container-main-title'><FormattedMessage id="admin.email.title" defaultMessage="" /></p>
            <p className='login-container-main-subTitle'><FormattedMessage id="admin.email.subTitle" defaultMessage="" /></p>
            <div className='login-container-main-inputWrapper'>
                <CustomTextInput
                    label='Email'
                    placeholder='nombre@ejemplo.com'
                    onChange={(e) => setEmailText(e.target.value)}
                    value={emailText}
                    error={props.loginProcessData?.config?.message}
                />
            </div>
            <div className='login-container-main-captchaWrapper'>
                <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey="6LdFokQfAAAAAMQ3MMEbQmaltswGk50-MKQoaS8m"
                    onChange={reCaptchaCapture}
                />
            </div>
            <div className='login-container-main-accountRememberContainer'>
                <div 
                    id="RememberAccount"
                    className='login-container-main-accountRememberContainer-box'
                    onClick={() => props.setRememberAccount(!props.rememberAccount)}
                >
                    <div 
                        className='login-container-main-accountRememberContainer-box-inner'
                        style={{ transform: props.rememberAccount ? `scale(${1})` : `scale(${0})` }}
                    >
                        <BoxCheck/>
                    </div>
                </div>
                <p className='login-container-main-accountRememberContainer-text'><FormattedMessage id="admin.email.recordar" defaultMessage="" /></p>
            </div>
            <div className='login-container-main-buttonWrapper'>
                <MainButton
                    id='Login'
                    disabled={buttonDisabled()}
                    text={localLoading ? 'Verificando...' : 'CONTINUAR'}
                    onPress={() => localLoading ? {} : handleLoginProcess()}
                />
            </div>
            <div className='login-container-main-buttonWrapper' style={{marginTop: 10}}>
                <GoogleLogin
                    clientId="321672482348-5era59e4iuc65ad0sjhs4tsrtjkk59sd.apps.googleusercontent.com"
                    buttonText="Login"
                    onSuccess={responseGoogle}
                    cookiePolicy={'single_host_origin'}
                    autoLoad={false}
                    render={renderProps => (
                        <MainButton
                            id='GoogleSign'
                            text='Continuar con Google'
                            onPress={renderProps.onClick}
                            google
                        />
                    )}
                />
            </div>
        </div>
    );

}

export default EmailStep;