import axios from './instanceLoginActions'
import axiosN from './instanceActions'
import { 
    netLoginProcess, 
    netLoginProcessId,
    getIdRecoveryPassword,
    postSuccessCodeRecoveryEmail,
    postSuccessNewPassword,
    getBasic,
    loginUserAuth,
    netUserMine,
    netSections
} from "../net/Connector";
import Cookies from 'universal-cookie';
import { genericErrorHandler } from '../util/GenericErrorHandler';

export const actionLoginProcess = (data: any, setLoginProcessData: any, recaptchaRef: any, setReCaptcha: any, setLocalLoading: any, loginProcessData: any) => {
    const cookies = new Cookies();
    axios({
        url: netLoginProcess(),
        method: 'post',
        data: data
    })
        .then((response) => {
            setLoginProcessData(response.data)
            setLocalLoading(false)
        })
        .catch((error) => {
            loginProcessData['config'] = {'message': genericErrorHandler(error)}
            setLoginProcessData(loginProcessData)
            recaptchaRef.current.reset();
            setReCaptcha(null);
            setLocalLoading(false)
        })
};

export const actionLoginProcessId = (data: any, id: any, setLoginProcessData: any, rememberAccount: any, navigate: any, setLocalLoading: any) => {
    const cookies = new Cookies();
    axios({
        url: netLoginProcessId(id), 
        method: 'patch', 
        data: data,
        headers: { 
            Authorization: getBasic()
        }
    })
        .then((response) => {
            if(!response.data.config.next) {
                setLoginProcessData(response.data)
            }
            if(!response.data.config?.message) {
                if(rememberAccount) {
                    cookies.set('rememberAccount', true);
                } else {
                    cookies.set('rememberAccount', false);
                }
                cookies.set('userAccessToken', 'Bearer ' + response.data.config.token);
                axiosN.get(netUserMine())
                    .then((responseB) => {
                        cookies.set('countryId', responseB.data.countryId);
                        axiosN.get(netSections())
                            .then((response) => {
                                const res = response?.data?.sort((a: any, b: any) => a.order - b.order);
                                navigate(res[0]?.path)
                            })
                            .catch((error) => {
                            })
                    })
                    .catch((error) => {
                    })
            }
            setLocalLoading(false)
        })
        .catch((error) => {
            setLocalLoading(false)
        })
};

export const actionNavigateOnEnter = (navigate: any) => {
    axiosN.get(netSections())
        .then((response) => {
            const res = response?.data?.sort((a: any, b: any) => a.order - b.order);
            navigate(res[0]?.path)
        })
        .catch((error) => {
        })
}

export const loginGoogleUser = (googleToken: any, navigate: any, rememberAccount: any, setLoginProcessData: any, loginProcessData: any, recaptchaRef: any, setReCaptcha: any, setLocalLoading: any) => {
    const cookies = new Cookies();
    axios({
        method: 'post',
        url: loginUserAuth(),
        params: {
            googleToken,
            source: "admin"
        },
        headers: {
            Authorization: getBasic(),
            'Content-Type': 'application/x-www-form-urlencoded',
        }
    })
        .then((response) => {
            if(rememberAccount) {
                cookies.set('rememberAccount', true);
            } else {
                cookies.set('rememberAccount', false);
            }
            cookies.set('userAccessToken', 'Bearer ' + response.data.access_token);
            axiosN.get(netUserMine())
                .then((responseB) => {
                    cookies.set('countryId', responseB.data.countryId);
                    const nextPath = responseB.data.privileges.filter((a: any) => a.includes(":read")).map((item: any) => item.replace(':read', ''));
                    if (!nextPath.includes("dashboard") && nextPath.includes("insurance_policies")) {
                        navigate("/insurance_policies")
                    } else if (nextPath.includes("dashboard")) {
                        navigate("/dashboard")
                    } else {
                        navigate("/" + nextPath[0])
                    }
                })
                .catch((error) => {
                })
        })
        .catch((error) => {
            loginProcessData['config'] = {'message': genericErrorHandler(error)}
            setLoginProcessData(loginProcessData)
            recaptchaRef.current.reset();
            setReCaptcha(null);
            setLocalLoading(false)
        });
};

export const recoveryPassReciveId = (userName: string, navigate: any) => {
    axios({
        url: getIdRecoveryPassword(), 
        method: 'post', 
        data: { username: userName }
    })
        .then((response) => {
            navigate('/recovery-code', { state: { userName: userName, id: response.data.id } })
        })
        .catch((error) => {
        })
}

export const resendEmailCode = (userName: string, setResendSuccess: any, setId: any) => {
    axios({
        url: getIdRecoveryPassword(), 
        method: 'post', 
        data: { username: userName }
    })
        .then((response) => {
            setId(response.data.id)
            setResendSuccess(true)
        })
        .catch((error) => {
        })
}


export const recoverySuccessCode = (id: string, code: string, navigate: any) => {
    axios({
        url: postSuccessCodeRecoveryEmail(id), 
        method: 'put', 
        data: { code: code }
    })
        .then((response) => {
            navigate('/recovery-code-form', { state: { id: id } });
        })
        .catch((error) => {
        })
}


export const recoverySuccessNewPassword = (id: string, password: string, navigate: any) => {
    axios({
        url: postSuccessNewPassword(id), 
        method: 'put', 
        data: { password: password }
    })
        .then((response) => {
            navigate('/recovery-code-form-finish', { state: { id: id } });
        })
        .catch((error) => {
        })
}