export const data = null;

export let sonLogo = 'https://resources.wecover.co/wecover/WeCover-logo-horizontal-color.png';
export let sonLogoBlanco = 'https://resources.wecover.co/wecover/WeCover-logo-blanco.png';

export let sonLogoSize  = '20%';

export const changeLogo = (url: string) => {
    sonLogo = url;
}

export const countries = [
    {
        id: 1,
        title: "ARGENTINA",
        img: null,
        url: '',
        lang: () => import('./assets/lang/es-AR.json'),
    }
];