const hexToRgba = (hex: any) => {
    const bigint = parseInt(hex?.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    const rgb = `${r}, ${g}, ${b}`;
    return `rgba(${rgb}, ${0.10})`;
};

export const estadoList = [
    {
        title: 'Solicitud pendiente incompleta',
        text: 'Una propuesta que fue presentada, pero que requiere de completar datos faltantes del usuario para poder ser procesada.',
        color: '#E6540B',
        colorOpacity: hexToRgba('#E6540B'),
        status: 'INCOMPLETED_PENDING_REQUEST'
    },
    {
        title: 'Solicitud pendiente',
        text: 'Una propuesta que fue presentada, pero que aún no ha sido completamente procesada o aprobada.',
        color: '#E6540B',
        colorOpacity: hexToRgba('#E6540B'),
        status: 'PENDING_REQUEST'
    },
    {
        title: 'Solicitud programada',
        text: 'Una propuesta que se encuentra planificada para ser procesada en una fecha futura determinada.',
        color: '#E6540B',
        colorOpacity: hexToRgba('#E6540B'),
    },
    {
        title: 'Solicitud vencida',
        text: 'Una propuesta que no fue completada o procesada dentro de un período de tiempo específico, generalmente debido a la falta de acción o respuesta por parte del solicitante.',
        color: '#858585',
        colorOpacity: hexToRgba('#858585'),
    },
    {
        title: 'Solicitud cancelada',
        text: 'Una propuesta que ha sido anulada o interrumpida antes de ser completamente procesada o aprobada.',
        color: '#858585',
        colorOpacity: hexToRgba('#858585'),
    },
    {
        title: 'Solicitud de baja',
        text: 'Una propuesta solicitada por el usuario final para dar de baja su cobertura, pero que aún no ha sido gestionada.',
        color: '#E6540B',
        colorOpacity: hexToRgba('#E6540B'),
        status: 'CANCEL_REQUESTED'
    },
    {
        title: 'Vigente',
        text: 'Una cobertura activa durante un período de tiempo. Implica que el asegurado está protegido contra los riesgos y eventos especificados.',
        color: '#008744',
        colorOpacity: hexToRgba('#008744'),
        status: 'FINISHED'
    },
    {
        title: 'No vigente',
        text: 'Una cobertura inactiva. En otras palabras, la cobertura no está en vigor ni proporciona protección ni beneficios en un momento determinado.',
        color: '#A705A7',
        colorOpacity: hexToRgba('#A705A7'),
        status: 'NOT_CURRENT'
    },
    {
        title: 'Renovada',
        text: 'Una cobertura existente que ha sido extendida o prolongada por otro período de tiempo después de su fecha de vencimiento original.',
        color: '#008744',
        colorOpacity: hexToRgba('#008744'),
        status: 'RENEWED'
    },
    {
        title: 'Cancelada',
        text: 'Una cobertura existente que ha sido interrumpida por el solicitante antes de su fecha de vencimiento prevista.',
        color: '#D12440',
        colorOpacity: hexToRgba('#D12440'),
        status: 'CANCELED'
    },
    {
        title: 'Pago rechazado',
        text: 'La transacción para pagar la prima de la cobertura no se completó con éxito debido a problemas con la tarjeta de crédito, falta de fondos u otras razones relacionadas con el proceso de pago.',
        color: '#D12440',
        colorOpacity: hexToRgba('#D12440'),
        status: 'REJECTED_PAYMENT'
    }
]