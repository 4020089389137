import React, { useEffect, useState } from 'react'
import './DescargarCotizacionModal.css'
import MainButton from '../../MainButton/MainButton';
import { actionsGetOffer } from '../../../actions';

const DescargarCotizacionModal = (props: any) => {

    const [packages, setPackages] = useState<any>(null);
    const [insurers, setInsurers] = useState<any>(null);
    const [list, setList] = useState<any>(null);
    const [selectedPackages, setSelectedPackages] = useState<any>([]);
    const [selectedAllFlag, setSelectedAllFlag] = useState<boolean>(false);

    useEffect(() => {
        if(props.offerId) {
            actionsGetOffer(props.offerId, setPackages);
        }
    }, [props.offerId])

    useEffect(() => {
        if(packages) {
            let insurerList: any = [];
            packages?.map((a: any) => {
                insurerList.push(a?.insurer)
            })
            setInsurers(removeRepeatedObjects(insurerList, 'id'))
        }
    }, [packages])

    useEffect(() => {
        if(packages && insurers) {
            let res: any = [];
            insurers?.map((a: any) => {
                let insurerListRes: any = [];
                insurerListRes['insurerName'] = a.name;
                packages?.map((b: any) => {
                    if(a?.id === b?.insurer?.id) insurerListRes.push(b)
                })
                res.push(insurerListRes);
            })
            setList(res);
        }
    }, [insurers, packages])

    const removeRepeatedObjects = (array: any, property: any) => {
        return array.filter((obj: any, index: any, self: any) =>
          index === self.findIndex((o: any) => o[property] === obj[property])
        );
    };

    const addPackage = (id: any) => {
        const res = [...selectedPackages];
        const index = res.indexOf(id);
        if (index === -1) {
            res.push(id);
        } else {
            res.splice(index, 1);
        }
        setSelectedPackages(res);
    }

    const selectAllPakages = () => {
        let res = [...selectedPackages];
        if(selectedPackages?.length === packages?.length) {
            res = [];
        } else {
            packages?.map((a: any) => {
                if (res.indexOf(a.id) === -1) {
                    res.push(a.id);
                }
            })
        }
        setSelectedPackages(res);
    }

    return (
        <div className='descargarCotizacionModal-container'>
            <span 
                className="material-symbols-rounded" 
                style={{ 
                    color: 'var(--gray700)', 
                    fontSize: 20,
                    position: 'absolute',
                    right: 12,
                    top: 12,
                    cursor: 'pointer'
                }}
                onClick={() => props.dismiss()}
            >close</span>
            <h6 className='descargarCotizacionModal-container-title bold'>Descargar cotización</h6>
            <b className='descargarCotizacionModal-container-subTitle regular large'>Seleccioná las coberturas que querés descargar</b>
            <div className='descargarCotizacionModal-container-list'>
                {list?.map((insurerItem: any) => (
                    <div className='descargarCotizacionModal-container-list-insurerLink'>
                        <b className='descargarCotizacionModal-container-list-insurerLink-title regular xSmall'>{insurerItem?.insurerName?.toUpperCase()}</b>
                        {insurerItem?.map((item: any, index: any) => (
                            <div 
                                key={index}
                                className='descargarCotizacionModal-container-list-insurerLink-item' 
                                style={{ opacity: selectedAllFlag ? 0.4 : 1, cursor: selectedAllFlag ? 'auto' : 'pointer' }}
                                onClick={() => {
                                    if(!selectedAllFlag) addPackage(item?.id)
                                }}
                            >
                                <div 
                                    className='descargarCotizacionModal-container-list-insurerLink-item-check' 
                                    style={{ backgroundColor: selectedPackages?.includes(item?.id) ? 'var(--primary500)' : 'transparent' }}
                                >
                                    {selectedPackages?.includes(item?.id) && <span className="material-symbols-rounded" style={{ fontSize: 16, color: 'var(--white)'}}>done</span>}
                                </div>
                                <b className='descargarCotizacionModal-container-list-insurerLink-item-text regular medium'>{item?.description}</b>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
            <div 
                className='descargarCotizacionModal-container-selectAll'
                onClick={() => {
                    selectAllPakages()
                    setSelectedAllFlag(!selectedAllFlag)
                }}
            >
                <div 
                    className='descargarCotizacionModal-container-selectAll-check' 
                    style={{
                        backgroundColor: selectedPackages?.length === packages?.length ? 'var(--primary500)' : 'transparent'
                    }}
                >
                    {selectedPackages?.length === packages?.length && <span className="material-symbols-rounded" style={{ fontSize: 16, color: 'var(--white)'}}>done</span>}
                </div>
                <b className='descargarCotizacionModal-container-selectAll-text regular medium'>Seleccionar todo</b>
            </div>
            <div className='descargarCotizacionModal-container-buttons'>
                <MainButton
                    text='CANCELAR'
                    inverted
                    onPress={() => props.dismiss()}
                />
                <MainButton
                    text='DESCARGAR'
                    disabled={selectedPackages?.length === 0}
                    onPress={() => props.descargar(selectedPackages, insurers)}
                />
            </div>
        </div>
    );

}

export default DescargarCotizacionModal;