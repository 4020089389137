import React from 'react'
import './TableHeader.css'
import UserAdminProfile from '../UserAdminProfile/UserAdminProfile';

const TableHeader = (props: any) => {

    return (
        <div className='tableHeader-container'>
            <UserAdminProfile
                optionList={props.userList}
                userData={props.userData}
                mini
            />
        </div>
    );

}

export default TableHeader;