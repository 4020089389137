import React, { useEffect, useRef, useState } from "react";
import "./GlobalStatusChange.css";
import { putChangeStatus } from "../../../../actions";
import statusImage from '../../../../assets/images/svgs/status.svg'
import { estadoList } from "../../../../util/EstadoListColors";
import { ReactSVG } from 'react-svg'

const GlobalStatusChange = (props: any) => {

  const [openGlobalAction, setOpenGlobalAction] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const optionGlobalRef: any = useRef();

  useEffect(() => {
    function handleClickOutside(event: any) {
        if (optionGlobalRef.current && !optionGlobalRef.current.contains(event.target)) {
            setOpenGlobalAction(false) 
        }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
        document.removeEventListener("mousedown", handleClickOutside);
    };
}, [optionGlobalRef]);

  return (
    <React.Fragment>
      {loading ? 
        <div className="globalStatusChange-loader">
          <span className="globalStatusChange-loader-loader"></span>
        </div>
        :
        <div className="globalStatusChange">
          <div className="globalStatusChange-img">
            <ReactSVG 
              src={statusImage}
              style={{opacity: props.userData?.role?.name === "ADMINISTRADOR" ? 1 : 0.6}}
              onClick={() => props?.list && props.userData?.role?.name === "ADMINISTRADOR" && setOpenGlobalAction(!openGlobalAction)}
            />
          </div>
          <span className="tooltip">{props.tooltip}</span>
          {openGlobalAction && 
            <div ref={optionGlobalRef} className='globalStatusChange-list'>
              {props?.list?.map((b: any) => (
                <b 
                  className='globalStatusChange-list-text regular medium'
                  onClick={() => {
                    setOpenGlobalAction(false)
                    const dataSend = {
                      'ids': props.selectedList,
                      'statusType': b
                    }
                    putChangeStatus(dataSend, props.refreshData, props.sendNotification, setLoading)
                  }}
                >
                    Cambiar a 
                    <b 
                        className='globalStatusChange-list-text-estado regular small' 
                        style={{ 
                            color: estadoList?.filter((c) => (c?.status == b))[0]?.color, 
                            backgroundColor: estadoList?.filter((c) => (c?.status == b))[0]?.colorOpacity 
                        }}
                    >{estadoList?.filter((c) => (c?.status == b))[0]?.title}</b>
                  </b>
              ))}
            </div>
          }
        </div>
      }
    </React.Fragment>
  )
};

export default GlobalStatusChange;
