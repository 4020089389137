import React, { useEffect, useRef, useState } from 'react'
import LeftArrow from '../../assets/images/svgs/leftArrow.svg';
import './OrdenarPor.css'
import { ReactSVG } from 'react-svg'

const OrdenarPor = (props: any) => {

    const [sortOpen, setSortOpen] = useState<boolean>(false)
    const [currentSort, setCurrentSort] = useState<string>('')
    const [nextSort, setNextSort] = useState<string>('')

    const listRef = useRef<any>(null);

    useEffect(() => {
        setCurrentSort(props.default)
    }, [props.default])

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (listRef.current && !listRef.current.contains(event.target)) {
                setSortOpen(false) 
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [listRef]);

    return (
        <div className='ordenarPor-container'>
            <div className='ordenarPor-container-labels'>
                <b className='ordenarPor-container-labels-ordenar bold large'>Ordenar por</b>
                <div className='ordenarPor-container-labels-action' onClick={() => setSortOpen(!sortOpen)}>
                    <b className='ordenarPor-container-labels-action-text bold large' style={{color: 'var(--primary500)'}}>&nbsp;&nbsp;{currentSort}</b>
                    <span 
                        className="material-symbols-rounded" 
                        style={{ 
                            color: 'var(--primary500)',
                            transform: !sortOpen ? 'rotate(0deg)' : 'rotate(180deg)', 
                            transition: 'transform 150ms ease' 
                        }}
                    >keyboard_arrow_down</span>
                </div>
            </div>
            {sortOpen && 
                <div className='ordenarPor-container-list' ref={listRef}>
                    {props.sortList?.map((a: any, index: number) => (
                        <p 
                            className='ordenarPor-container-list-text'
                            style={{ 
                                backgroundColor: 
                                    nextSort === a.name ? 'var(--primary500)' :
                                    currentSort !== nextSort && nextSort !== '' && currentSort === a.name ? 'var(--gray100)' :
                                    currentSort === a.name ? 'var(--primary500)' : 
                                    'auto',
                                color: 
                                    nextSort === a.name ? 'var(--white)' :
                                    currentSort !== nextSort && nextSort !== '' && currentSort === a.name ? 'var(--gray700)' :
                                    currentSort === a.name ? 'var(--white)' : 
                                    'auto',
                            }}
                            key={index}
                            onMouseDown={() => setNextSort(a.name)}
                            onMouseUp={() => setNextSort('')}
                            onClick={() => {
                                if(currentSort !== a.name) {
                                    setSortOpen(false)
                                    setCurrentSort(a.name)
                                    props.itemSelected(a)
                                }
                            }}
                        >{a.name}</p>
                    ))}
                </div>
            }
        </div>
    );

}

export default OrdenarPor;