import React, { useEffect, useState, useRef } from 'react'
import './SelectorInput.css'

const SelectorInput = (props: any) => {

    const [sortOpen, setSortOpen] = useState<boolean>(false)
    const [localInput, setLocalInput] = useState<string>('')
    const [localList, setLocalList] = useState<any>(null)

    const mainRef: any = useRef();

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (mainRef.current && !mainRef.current.contains(event.target)) {
                setSortOpen(false)
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [mainRef]);

    useEffect(() => {
        if(props.disabled) setSortOpen(false)
    }, [props.disabled])

    useEffect(() => {
        setLocalList(props.sortList)
    }, [props.sortList])

    const filterByText = (e: string) => {
        setLocalInput(e)
        let auxShow = props.sortList?.filter((a: any) => (a.descripcion).toLowerCase().includes(e.toLowerCase()))
        setLocalList(auxShow)
    }

    return (
        <div ref={mainRef} className='selectorInput-container'>
            <div 
                className='selectorInput-container-label'
                style={{
                    backgroundColor: props.disabled ? 'transparent' : 'var(--white)',
                    borderTopLeftRadius: 15,
                    borderTopRightRadius: 15,
                    borderBottomLeftRadius: sortOpen ? 0 : 15,
                    borderBottomRightRadius: sortOpen ? 0 : 15,
                    border: props.disabled ? '1px solid var(--gray200)' : '1px solid var(--primary500)',
                }}
            >
                <input
                    className='selectorInput-container-labels-input regular medium'
                    style={{ color: props.disabled ? 'var(--gray700)' : 'var(--primary500)'}}
                    value={props.value ? props.value : localInput}
                    onChange={(e: any) => filterByText(e.target.value)}
                    onFocus={() => setSortOpen(true)}
                    placeholder={props.placeholder}
                    disabled={props.value}
                />
                {!props.disabled && <span 
                    onClick={() => {
                        if(props.disabled) {}
                        else {
                            if(sortOpen) setSortOpen(false)
                            else {
                                setSortOpen(true)
                                props.itemSelected(null)
                                filterByText('')
                            }
                        }
                    }}
                    className="material-symbols-rounded" 
                    style={{ 
                        color: 'var(--primary500)',
                        fontSize: 20,
                        transform: !sortOpen ? 'rotate(0deg)' : 'rotate(180deg)', 
                        transition: 'transform 150ms ease',
                        marginRight: 4,
                        userSelect: 'none',
                        cursor: props.disabled ? 'auto' : 'pointer'
                    }}
                >expand_more</span>}
            </div>
            <div 
                className='selectorInput-container-list'
                style={{ display: sortOpen ? 'flex' : 'none'}}
            >
                {localList?.map((a: any, index: number) => (
                    <div 
                        className='selectorInput-container-list-item'
                        style={{
                            borderBottomRightRadius: index === localList?.length -1 ? 15 : 0,
                            borderBottomLeftRadius: index === localList?.length -1 ? 15 : 0,
                        }}
                        onClick={() => {
                            setSortOpen(false)
                            props.itemSelected(a)
                            filterByText('')
                        }}
                        key={index}
                    >
                        <p className='selectorInput-container-list-item-text regular medium' >{a.branchName ? a.branchName : a.descripcion}</p>
                    </div>
                ))}
            </div>
        </div>
    );

}

export default SelectorInput;