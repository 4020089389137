import React from 'react'
import { useEffect } from 'react';
import BurgerMenu from '../../components/BurgerMenu/BurgerMenu';
import './Cotizaciones.css'
import GenericTableAndFilters from '../../components/GenericTableAndFilters/GenericTableAndFilters';
import { useLocation, useNavigate } from 'react-router-dom';
import TableHeader from '../../components/TableHeader/TableHeader';

const Cotizaciones = (props: any) => {

    const navigate = useNavigate();
    const location = useLocation();

    return (
        <div className='cotizaciones-container'>
            <BurgerMenu principalList={props.principalList}/>
            <div className='cotizaciones-container-main'>
                <TableHeader countryId={props.userData?.countryId} optionList={props.userList} userData={props.userData}/>
                <GenericTableAndFilters
                    canDownload={props.userData?.privileges?.filter(function(e: any) { return e === 'insurance_policies:download' }).length > 0}
                    data={{
                        type: 'quotations',
                        search: 'v2/quotations',
                        createTitle: 'CREAR COTIZACIÓN',
                        title: props.principalList?.filter((a: any) => a.path === location?.pathname)[0].name,
                        createPath: '/cotizaciones/create'
                    }}
                    idType='ID'
                    goToDetails={(id: any) => navigate('/cotizaciones/' + id + '#Detalle%20de%20usuario')}
                    canCreate={props.userData?.privileges?.filter(function(e: any) { return e === 'insurance_policies:create' }).length > 0}
                    setGlobalLoading={props.setGlobalLoading}
                />
            </div>
        </div>
    );
}

export default Cotizaciones;