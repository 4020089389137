import React, { useEffect, useRef, useState } from 'react'
import './TextWithBackGround.css'
import { putChangeStatus } from '../../../actions'

const TextWithBackGround = (props: any) => {

    const [data, setData] = useState<any>(null)
    const [openList, setOpenList] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false);

    const componentRef: any = useRef(null);

    useEffect(() => {
        const handleOutsideClick = (event: any) => {
          if (componentRef?.current && !componentRef?.current?.contains(event.target)) {
            setOpenList(false)
          }
        };
      
        document.addEventListener('mousedown', handleOutsideClick);
      
        return () => {
          document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    const handleParse = () => {
        try {
            const parsedObject = JSON.parse(props.data);
            setData(parsedObject);
        } catch (error) {
            setData(props.data);
        }
    };

    const hexToRgba = (hex: any) => {
        const bigint = parseInt(hex?.slice(1), 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;
        const rgb = `${r}, ${g}, ${b}`;
        return `rgba(${rgb}, ${0.10})`;
    };

    useEffect(() => {
        if(props.data) {
            handleParse();
        }
    }, [props.data])

    const itemClicked = (a: any) =>  {
        setOpenList(false)
        const dataSend = {
            'ids': [data?.id],
            'statusType': a?.status
        }
        putChangeStatus(dataSend, props.refreshData, props.sendNotification, setLoading)
    }

    return (
        <div className='textWithBackGround' ref={componentRef}>
            <div 
                className='textWithBackGround-main'
                style={{ backgroundColor: hexToRgba(data?.color) }}
                onClick={() => {if(data?.list) setOpenList(!openList)}}
            >
                <b 
                    className='textWithBackGround-main-text medium regular'
                    style={{ color: data?.color, padding: data ? 5 : 0 }}
                >{data?.h1 ? data?.h1 : data}</b>
                {(data?.list?.length > 0 && props.userData?.role?.name === "ADMINISTRADOR") && 
                    <div 
                        className='textWithBackGround-main-arrow'
                        style={{ transform: openList ? 'rotate(180deg)' : 'rotate(0deg)' }}
                    >
                        <span className="material-symbols-rounded" style={{fontSize: 15}}>arrow_drop_down</span>
                    </div>
                }
            </div>
            {(openList && data?.list?.length) > 0 &&
                <div className='textWithBackGround-popup'>
                    <div className='textWithBackGround-popup-main'>
                        <b className='textWithBackGround-popup-main-title xSmall regular'>CAMBIAR ESTADO</b>
                        {data?.list?.map((a: any) =>(
                            <div 
                                className='textWithBackGround-main'
                                style={{ backgroundColor: hexToRgba(a.color) }}
                                onClick={() => itemClicked(a)}
                            >
                                <b 
                                    className='textWithBackGround-text medium regular'
                                    style={{ color: a?.color, padding: 5 }}
                                >
                                    {a.name}
                                </b>
                            </div>
                        ))}
                    </div>
                </div>
            }
        </div>
    );
}

export default TextWithBackGround;